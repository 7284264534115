import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import React, {useEffect, useState} from "react";
import Particles, {initParticlesEngine} from "@tsparticles/react";
import {loadFull} from "tsparticles";
import particlesOptions from "./particles.json";
import Navbar from "react-bootstrap/Navbar";
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import {scroller} from "react-scroll";
import portrait from "../src/Kevin.jpg";


function App() {
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    return (
        <div className='App'>
            {init && <Particles options={particlesOptions}/>}
            <div>
                <Navbar variant="white" expand="lg" className='nav-bar' fixed="top">
                    <Container style={{ paddingLeft: '20px' }}>
                        <Navbar.Brand className="text-color nav-bar-elements" onClick={
                                () => scroller.scrollTo('starter-content', { smooth: true, offset: 0, duration: 500})}
                                >Kevin Zhao
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                            <Nav.Link className="text-color nav-bar-elements" onClick={
                                () => scroller.scrollTo('starter-content', { smooth: true, offset: 0, duration: 500})}
                                >About
                            </Nav.Link>
                            <Nav.Link className="text-color nav-bar-elements" onClick={
                                () => scroller.scrollTo('contact-me', { smooth: true, offset: -70, duration: 500})}
                                >Contact
                            </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
      
            <div className='starter-content' id='starter-content'>
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='text-center'><img class="rounded-circle avatar" alt="avatar1" src={portrait} /></div>
                                            
                        </Col>
                        <Col xs={12} md={6}>
                            <div><span className="about-me display-1">Kevin Zhao</span></div>

                            <div><h2 className="about-me">Hello!👋</h2></div>
                            
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='main-content'>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs lg="8">
                            <h4>
                                Nice to meet you! I'm currently a 4th year student at Simon Fraser University. 
                                For now, the purpose of this website is a landing page, and a way to send a message to me!
                                I'll do my best to get back to you as soon as possible.
                            </h4>
                            
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="links">
                <Container>

                <Row className="justify-content-md-center">

                        <Col xs = "12" lg="3" className='mb-3'>
                            <div className="d-grid gap-2">
                                <Button variant="success" size="lg" href='https://www.linkedin.com/in/k--zhao/'>
                                    <FontAwesomeIcon icon={faLinkedin} /> Linkedin
                                </Button>
                            </div>
                        </Col>
                        <Col xs = "12" lg="2" className='mb-3'>
                            <div className="d-grid gap-2">
                                <Button variant="success" size="lg"  href="/ResumeWebsite.pdf">
                                   <FontAwesomeIcon icon={faFile}/> Resume
                                </Button>
                            </div>
                        </Col>
                        <Col xs = "12" lg="3" className='mb-3'>
                            <div className="d-grid gap-2">
                                <Button variant="success" size="lg" href="https://github.com/K-zhao">
                                    <FontAwesomeIcon icon={faGithub} /> Github
                                </Button>
                            </div>

                        </Col>
                </Row>
                </Container>
            </div>

            <div className='my-experience'>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs lg="8">
                            <h2>Experience</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center experience">
                        <Col xs lg="8">
                            <h5>Software Developer Internship - BC Pension Corporation</h5>
                            <p>Sept 2021 - August 2022</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center experience">
                        <Col xs lg="8">
                            <h5>Machine Learning Mentorship - Borealis AI</h5>
                            <p>March 2021 - May 2021</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='contact-me' id='contact-me'>
                <Container>
                    <h1>Contact me</h1>
                    <Row className="justify-content-md-center">
                        <Col xs lg="8">
                            <Form action="https://send.pageclip.co/fG8YYRD2pRgkPZYwa94pomYbr1RwbNhg" class="pageclip-form" method="post">
                                <Form.Group className="mb-3 text-color" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email address:</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="name@example.com" />
                                </Form.Group>
                                <Form.Group className="mb-3 text-color" controlId="exampleForm.ControlInput2">
                                    <Form.Label>Name:</Form.Label>
                                    <Form.Control as="textarea" name="Name" placeholder="Name" />
                                </Form.Group>
                                <Form.Group className="mb-3 text-color" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Message:</Form.Label>
                                    <Form.Control as="textarea" name="Message" rows={3} />
                                </Form.Group>
                                <Button type="submit" class="pageclip-form__submit" style={{backgroundColor: '#7c957c'}}>
                                    <span>Send</span>
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='footer'>
                <footer className='nav-bar py-3 mt-4'>
                    <Container className='text-center text-light'>
                        <p className='my-auto'>© 2024 - Kevin Zhao</p>
                    </Container>
                </footer>

            </div>

        </div>
    );
}

export default App;
